<template>
  <div class="e-faq">
    <header class="e-faq__head">
      <h1>{{ $t('system.navigation.support') }}</h1>
    </header>
    <div class="e-faq__content">
      <template v-if="items.length">
        <div v-for="group in Object.keys(groupedItems)" :key="group + 'container'">
          <h2>{{ group }}</h2>
          <el-collapse v-model="activeNames">
            <el-collapse-item
              v-for="(item, index) in groupedItems[group]"
              :key="group + index"
              class="e-faq__collapse-item"
              :name="group + index"
            >
            <template slot="title">
              <h4 class="el-collapse-item__title">
                {{ item.question | noCategory }}
              </h4>
            </template>
            <div v-html="item.answer" />
            </el-collapse-item>
          </el-collapse>
        </div>
      </template>
      <template v-else>
        <div class="e-faq__no-items">{{ $t('system.faq.noItems') }}</div>
      </template>
    </div>
  </div>
</template>

<script>

const getContent = value =>
  value && value.indexOf(':') > -1
    ? value.substring(value.indexOf(':') + 2, value.length)
    : value
const getCategory = value =>
  value && value.indexOf(':') > -1
    ? value.substring(0, value.indexOf(':'))
    : value
export default {
  name: 'FaqWrapper',
  filters: {
    noCategory(value) {
      return getContent(value)
    },
    category(value) {
      return getCategory(value)
    }
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data: () => ({
    activeNames: []
  }),
  computed: {
    groupedItems() {
      const groups = {}
      this.items.forEach(item => {
        const category = getCategory(item.question)
        if (!category) {
          return
        }
        if (!(category in groups)) {
          groups[category] = []
        }
        groups[category].push(item)
      })
      return groups
    }
  }
}
</script>

<style lang="scss">
@import '_assets/faq';
</style>
<style lang="scss" scoped>
.e-faq__inner {
  &.ps {
    overflow: auto !important;

    @include break(l) {
      overflow: hidden !important;
      @at-root .mobile {
        overflow: auto !important;
      }
    }
  }
}
</style>
