<template>
<InnerScroll :loading="pageDataLoading" :transparent="true">
  <Faq :items="faq" />
  </InnerScroll>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Faq from 'organisms/FAQ/FAQ'
import InnerScroll from '../InnerScroll/InnerScroll.vue'

export default {
  components: {
    Faq,
    InnerScroll
},
  data: () => ({
    faqLoading: false
  }),
  computed: {
    ...mapGetters(['faq', 'pageDataLoading'])
  },
  mounted() {
    this.getFAQ()
  },
  methods: {
    ...mapActions(['getFAQ'])
  }
}
</script>

<style lang="scss"></style>
